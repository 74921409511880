html {
    scroll-behavior: smooth;
}

.redlink:hover {
	color: red;
}

.eelink:hover {
	color: #7289DA;
}

.crmlink:hover {
    color: #7200A2;
}